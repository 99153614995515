import React, { FunctionComponent } from 'react'
import Head from '../components/head'
import Layout from '../components/layout'

import '../styles/content-page.scss'

const AboutPage: FunctionComponent = () => {
  return (
    <Layout
      className='content-page'
    >
      <Head
        title='About'
      />

      <p>
        Lisa M. Henry is a contemporary realist working in charcoal, graphite, watercolor, oils, and ink. After receiving her BFA in Design and traveling throughout Europe and North America, she returned to western Massachusetts to be part of its vibrant artistic community and to enjoy the natural beauty of the Berkshire foothills. She owns a farm where she tends to her orchard, a flock of chickens, several cats, and a beagle.
      </p>
      <p>
        The Academic Artists Association selected Lisa&rsquo;s work to be part of several national exhibitions of contemporary realism. Her drawings were also displayed at the D&rsquo;Amour Museum of Fine Arts.
      </p>
      <p>
        Lisa has been an Artist in Residence at the 11 South Gallery, a member of the Old Deerfield Painting Group and the Deerfield Valley Art Association, and has served on the Greenfield Cultural Council. She is a trustee and the shopkeeper at the Ashfield Historical Society. She sings tenor and plays bass guitar.
      </p>
    </Layout>
  )
}

export default AboutPage
